import { makeStyles, ThemeProvider } from "@material-ui/core";
import "font-proxima-nova/style.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "typeface-inter";
import Notifications from "./components/notifications";
import UserContextProvider, { useUserContext } from "./components/userContext";
import useTokenRefresh from "./helpers/useTokenRefresh";
import Layout from "./layout";
// import LoginPage from "./pages/login";
import NotFoundPage from "./pages/notFound";
import { darkTheme } from "./theme";

// ts-ignore
import { AnimatedSwitch } from "react-router-transition";
import ScrollToTop from "./helpers/ScrollToTop";
import AlertProvider from "./components/alerts/AlertProvider";

// lazy load all pages
// this will make first page load faster, but slightly slow down loading other pages
const HomePage = React.lazy(() => import("./pages/home"));
const LoginPage = React.lazy(() => import("./pages/login"));
const DevicesPage = React.lazy(() => import("./pages/devices"));
const InvoicesPage = React.lazy(() => import("./pages/invoices"));
const SettingsPage = React.lazy(() => import("./pages/settings"));
const AdminPage = React.lazy(() => import("./pages/admin"));
const FinancePage = React.lazy(() => import("./pages/finance"));

const useStyles = makeStyles((theme) => ({
  switchWrapper: {
    position: "relative",
    "& > div": {
      position: "absolute",
      minHeight: "100vh",
      width: "100%",
      paddingBottom: theme.spacing(5),

      [theme.breakpoints.down("md")]: {
        paddingBottom: `calc(56px  + ${theme.spacing(3)} )`,
      },
    },
  },
}));

const RootRouter = () => {
  const { switchWrapper } = useStyles();
  const { onLogin, isAstroFinance, isAstroAdmin } = useUserContext();

  useTokenRefresh();

  return (
    <Switch>
      <Route path="/login">
        <Suspense fallback={<div>Loading</div>}>
          <LoginPage onLoginSuccess={onLogin} />
        </Suspense>
      </Route>
      <Route>
        <Notifications>
          <Layout>
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className={switchWrapper}
            >
              <Route path="/" exact>
                <Suspense fallback={<div>Loading</div>}>
                  <HomePage />
                </Suspense>
              </Route>
              <Route path="/invoices" exact>
                <Suspense fallback={<div>Loading</div>}>
                  <InvoicesPage />
                </Suspense>
              </Route>
              <Route path="/settings" exact>
                <Suspense fallback={<div>Loading</div>}>
                  <SettingsPage />
                </Suspense>
              </Route>
              {!isAstroFinance && (
                <Route path="/devices">
                  <Suspense fallback={<div>Loading</div>}>
                    <DevicesPage />
                  </Suspense>
                </Route>
              )}
              {(isAstroFinance || isAstroAdmin) && (
                <Route path="/finance" exact>
                  <Suspense fallback={<div>Loading</div>}>
                    <FinancePage />
                  </Suspense>
                </Route>
              )}
              {isAstroAdmin && (
                <Route path="/admin" exact>
                  <Suspense fallback={<div>Loading</div>}>
                    <AdminPage />
                  </Suspense>
                </Route>
              )}
              <Route>
                <NotFoundPage />
              </Route>
            </AnimatedSwitch>
          </Layout>
        </Notifications>
      </Route>
    </Switch>
  );
};

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <ScrollToTop />
        <UserContextProvider>
          <RootRouter />
        </UserContextProvider>
      </Router>
      <AlertProvider />
    </ThemeProvider>
  );
}

export default App;
