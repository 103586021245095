import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  CssBaseline,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PersonIcon from "@material-ui/icons/Person";
import TailFinReversed from "../../components/icons/TailFinReversed";
import BankSafe from "../../components/icons/BankSafe";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useUserContext } from "../../components/userContext";

const useStyles = makeStyles({
  bottomNav: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgb(7,29,53)",
    padding: 40,
  },
});

const BottomBar = () => {
  const { isAstroAdmin, isAstroFinance } = useUserContext();
  const location = useLocation();
  const classes = useStyles();
  const [value, setValue] = React.useState(location.pathname);

  const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  return (
    <>
      <CssBaseline />
      {isMobileDisplay ? (
        <BottomNavigation
          className={classes.bottomNav}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            component={Link}
            to="/"
            value="/"
            label="Home"
            icon={<HomeIcon />}
          />
          {(!isAstroFinance || isAstroAdmin) && (
            <BottomNavigationAction
              component={Link}
              to="/devices"
              value="/devices"
              label="Devices"
              icon={<TailFinReversed />}
            />
          )}
          <BottomNavigationAction
            component={Link}
            to="/settings"
            value="/settings"
            label="Settings"
            icon={<PersonIcon />}
          />
          {(isAstroFinance || isAstroAdmin) && (
            <BottomNavigationAction
              component={Link}
              to="/finance"
              value="/finance"
              label="Finance"
              icon={<BankSafe />}
            />
          )}
          <BottomNavigationAction
            component={Link}
            to="/invoices"
            value="/invoices"
            label="Invoices"
            icon={<AttachMoneyIcon />}
          />
          {isAstroAdmin && (
            <BottomNavigationAction
              component={Link}
              to="/admin"
              value="/admin"
              label="Admin"
              icon={<VerifiedUserIcon />}
            />
          )}
        </BottomNavigation>
      ) : null}
    </>
  );
};

export default BottomBar;
